// colors

$white: #fff;
$black: #000;
$blackLight: #2F312D;
$darkBlue: #092533;
$grey: rgba(9, 37, 51, 0.6);
$darkGrey: #979797;
$gray2: #84959d;

$parisWhite: #D3E5DD;

$redOrange: #FF563C;

$redLight: #FF5555;

$greenLight: #10CD75;

$blue: #2FB8FF;


// Fonts
$rubik: 'Rubik', sans-serif;
$karla: 'Karla', sans-serif;