@import "reset";

@import "global";

/* apply a natural box layout model to all elements, but allowing components to change */
*, *:before, *:after {
  box-sizing: border-box;
}

button {
  &, &:focus {
    outline: none;
  }
}

img {
  max-width: 100%;
}