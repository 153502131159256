@import "variables";

html, body {
  height: 100%;
}

body {
  font-family: $karla;
  font-size: 14px;
  line-height: 1.2;
  flex: 1;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.Toastify__toast {
  border-radius: 8px;
  margin: 3px 5px 5px 5px;
}

.Toastify__toast--success {
  background-color: $greenLight;
}

.Toastify__toast--error {
  background-color: $redLight;
}
